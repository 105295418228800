<template>
  <div id="app" :class="{'scroll-down': scrollDown}">
    <div v-if='init'>
    <header class="container">
      <p class="logo" @click="scrollDown=false" :class="{'large-logo': scrollDown}"><img src="./assets/logo2.png" alt="Angle Capital"></p>
      <p class="meta">Meta + BlockChain</p>
    </header>
    <section class="banner">
      <b></b>
      <dl class="slogan">
        <dt><img src="./assets/angle.png" alt="angle"></dt>
        <dd>
          <h1>ANGLE CAPITAL</h1>
          <p>DISCOVER AND INVEST IN EXCELLENT  BLOCKCHAIN  PROJECTS</p>
        </dd>
      </dl>
      <dl class="arrows">
        <dt class="animate__animated animate__bounce animate__slower animate__infinite"><i @click="scrollDown=true"></i></dt>
        <dd class="arrow-wave">
          <span v-for="i in 3" :key="i"></span>
        </dd>
      </dl>
    </section>
    <section class="content">
      <div class="container wrap">
        <ul>
          <li @click="type='about'" :class="{'current': type=='about'}">About Us</li>
          <li @click="type='domain'" :class="{'current': type=='domain'}">Domain & Layout</li>
          <li @click="setFuture" :class="{'current': type=='future'}">Future & Environment</li>
        </ul>
        <div class="about" v-show="type=='about'">
          <ol>
            <li :class="{'animate__animated animate__fadeInLeft': type=='about'}"><img src="./assets/a1.png" alt=""></li>
            <li :class="{'animate__animated animate__fadeInLeft animate__delay-1s': type=='about'}"><img src="./assets/a2.png" alt=""></li>
            <li :class="{'animate__animated animate__fadeInLeft animate__delay-2s': type=='about'}"><img src="./assets/a3.png" alt=""></li>
          </ol>
          <dl :class="{'animate__animated animate__fadeInDown': type=='about'}">
            <dt><b>Vision</b></dt>
            <dd>To be the most valuable investment player in the digital technology sector.</dd>
          </dl>
          <dl :class="{'animate__animated animate__fadeInDown animate__delay-1s': type=='about'}">
            <dt><b>Mission</b></dt>
            <dd>To discover, invest and help outstanding blockchain projects and help cultivate new forces in the digital technology field.</dd>
          </dl>
          <dl :class="{'animate__animated animate__fadeInDown animate__delay-2s': type=='about'}">
            <dt><b>Philosophy</b></dt>
            <dd>To use our substantial resources as a force for ethical technological disruption. We will be meticulous in our selection of projects that fit our ethos across a multitude of fields.</dd>
          </dl>
        </div>

        <div class="domain" v-if="type=='domain'">
          <h1 :class="{'animate__animated animate__fadeInDown': type=='domain'}" >METAVERSE</h1>
          <p :class="{'animate__animated animate__fadeInDown animate__delay-1s': type=='domain'}" >Blockchain technology is indispensable for the realization of the Metaverse. </p>
          <p :class="{'animate__animated animate__fadeInDown animate__delay-2s': type=='domain'}"><img src="./assets/tu1.png" alt=""></p>
          <p :class="{'animate__animated animate__fadeInDown animate__delay-3s': type=='domain'}">Blockchain can provide players with a digital virtual identity, create a new economic system through smart contracts, create rich and diverse content through NFTs, and realize a truly decentralized network. For the Metaverse, blockchain technology makes it possible to switch between reality and the virtual world.</p>
        </div>

        <div class="future" v-if="type=='future'" @click="futureImg=!futureImg">
          <h1 :class="{'animate__animated animate__fadeInDown': type=='future'}">Supporting and empowering carbon neutrality</h1>
          <div class="balls" :class="{'animate__animated animate__fadeInDown': type=='future'}">
            <i v-for="i in 5" :key="i" :class="{'switchImg': futureImg}"></i>
            <b></b>
          </div>
          <div class="para" :class="{'animate__animated animate__fadeInUp': type=='future'&&!futureImg}" v-if="!futureImg">
            <p>The successful application of blockchain in the field of anti-counterfeit traceability and supply chain management has fully demonstrated the technology's powerful ability to bridge the dotted line and maintain information integrity.</p>

            <p :class="{'animate__animated animate__fadeInUp': type=='future'&&!futureImg}">There is a growing call for a blockchain-based carbon trading platform, with agencies saying the technology can help make the process of developing, trading, and auctioning allowances for carbon assets trustworthy and self-organizing. It is expected that by 2030, the cumulative trading value of carbon emissions may exceed $100 billion. The market size of blockchain technology in global carbon trading will see a trillion dollar breakthrough. Companies with native on-chain carbon data will be able to capitalize on this opportunity.</p>
          </div>
          <div class="img" :class="{'animate__animated animate__fadeInDown animate__faster': type=='future'&&futureImg}" v-else>
            <img src="./assets/tu2.jpg" alt="">
          </div>
        </div>
      </div>
    </section>
    </div>
    <div v-else></div>
  </div>

</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data(){
    return {
      type: 'about',
      futureImg: false,
      init: false,
      scrollDown: false
    }
  },
  created() {
    var timer = setInterval( () => {
        if (document.readyState === 'complete') {
            this.init = true;
            window.clearInterval(timer)
        }
    }, 1000)
  },
  methods: {
    setFuture(){
      this.type="future";
      this.futureImg=false;
    }
  }
}
</script>

<style lang="less" scoped>
@font-face {
  font-family: Roboto;
  src: url(./assets/Roboto-Thin.ttf);
}
@font-face {
  font-family: Roboto;
  src: url(./assets/Roboto-BoldCondensed.ttf);
  font-weight: bold;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/Open Sans.ttf');
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/Open Sans Light.ttf');
  font-weight: lighter;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/Open Sans Bold.ttf');
  font-weight: bolder;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  position: relative;
  top: 0;
  transition: top .5s ease-out;
  &.scroll-down {
    top: -100vh;
  }
}

.container {
  min-width: 1200px;
  max-width: 55vw;
}

header {
  position: fixed;
  top: 36px;
  left: 0;right:0;
  z-index: 99999;
  margin: auto;
  .logo {
    animation: fadeInDown;
    animation-duration: 2s;
    cursor: pointer;
    img {
      width: 128px;
      transition: width .5s ease-in;
    }
    &.large-logo img {
      width: 210px;
    }
  }
  .meta {
    animation: fadeInDown;
    animation-duration: 2s;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    font-weight: bolder;
    &:after {
      content: '';
      display:  block;
      background-image: url(./assets/metax.png);
      background-repeat: no-repeat;
      background-size: contain;
      height: 50px;
      width: 100%;
      margin-top: 5px;
    }
  }
}
section {
  width: 100%;
  height: 100vh;
  &.banner {
    background-image: url(./assets/bg1900s.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    animation: fadeIn;
    animation-duration: 1s;
    b {
      position: absolute;
      top:-50%;
      left: -50%;
      width: 100%;
      height: 200%;
      background-color: rgba(0, 0, 0, 0.8);
      box-shadow: 0 20px 0 20px rgba(0, 0, 0, 0.2);
      transform: rotate(-45deg);
      transform-origin: right center;
      animation: fadeIn;
      animation-duration: 2s;
      -webkit-filter: blur(2px);
      filter: blur(2px);
    }
    dl.slogan {
      position: absolute;
      left: 0; right: 0;
      top: 0; bottom: 50px;
      margin: auto;
      width: 700px;
      height: 320px;
      display: flex;
      white-space:nowrap;
      dt {
        animation: fadeInLeft;
        animation-duration: 2s;
        img {
          height: 320px;
        }
      }
      dd {
        animation: fadeInRight;
        animation-duration: 2s;
        margin-top: 100px;
        margin-left: -100px;
        font-family: Roboto;
        h1 {font-size: 44px; letter-spacing: 10px;font-weight: bold;}
        p {font-size: 18px;line-height: 4;}
      }
    }
    .arrows {
        position: absolute;
        left: 0; right: 0;
        bottom: 10%;
        margin: auto;
        text-align: center;
        animation: fadeInDown;
        animation-duration: 1s;
      i {
        display: inline-block;
        width: 44px;
        height: 44px;
        background-image: url(./assets/xia1.png);
        background-size: contain;
        cursor: pointer;
      }
      .arrow-wave {
        display: inline-block;
        cursor: pointer;
        margin-top: 30px;
        font-size: 0;
      }

      .arrow-wave span {
        display: block;
        position: relative;
        width: 0; 
        height: 0; 
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #fff;
        margin-top: -1px;
      }

      .arrow-wave span:nth-child(1n) {
        animation: animate-arrow-wave 2s infinite;
        animation-delay: .25s;
      }
      .arrow-wave span:nth-child(2n) {
        animation: animate-arrow-wave 2s infinite;
        animation-delay: .50s;
      }

      .arrow-wave span:nth-child(3n) {
        animation: animate-arrow-wave 2s infinite;
        animation-delay: .75s;
      }

      @keyframes animate-arrow-wave {
        0%   { opacity: 0.2; }
        25%  { opacity: 0.2; }
        50%  { opacity: 0.2; }
        75%  { opacity: 1.0; }
        100% { opacity: 0.2; }
      }

    }
  }
  &.content {
    background-color: #13181D;
    .wrap {
      margin: 0 auto;
      position: relative;
      top: 20%;
      display: flex;
      &>ul {
        flex: 1;
        text-align: right;
        margin-right: 4%;
        margin-top: 16%;
        font-family: Roboto;
        background: #13181D;
        li {
          font-size: 20px;
          margin-bottom: 40px;
          text-transform: uppercase; 
          font-weight: bold; 
          position: relative;
          cursor: pointer;
          &::after {
            position: absolute;
            left: 0;
            content: '';
            width: 100%;
            height: 1px;
            background: #38A2D2;
            bottom: -14px;
            transform: scale(0, 1);
            transition: transform .3s;
            transform-origin: right top;
          }
          &:hover::after {
              transform-origin: left top;
              transform: scale(1, 1);
          }

          &.current {
            cursor: default;
          }
          &.current::before {
            content: '';
            position: absolute;
            bottom: -16px;
            right: 0;
            width: 75px;
            height: 5px;
            background: #38A2D2;
          }
        }
      }
      >div {
        flex: 3;
        margin-left: 4%;
      }
      div.about {
        ol {
          text-align: right;
          margin-bottom: 60px;
          li {
            display: inline-block;
            margin-left: 20px;
            img {
              width: 79px;
            }
          }
        }
        dl {
          display: flex;
          padding: 30px 0;
          border-bottom: 1px solid rgba(127,156,169, .2);
          dt {
            text-transform: uppercase; 
            width: 200px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            background: #38A2D2;
            color: #000;
            margin-right: 60px;
            transform: skew(45deg, 0deg);
            font-family: 'Open Sans';
            b {
              font-size: 14px;
              display: block;
              font-weight: bolder;
              transform: skew(-45deg, 0deg);
            }
          }
          dd {
            width: 100%;
            font-size: 20px;
            line-height: 1.5;
          }
        }
      }

      div.domain {
        h1 {
          font-size: 44px;
          font-weight: bolder;
          font-family: 'Open Sans';
          &:after {
            content: '';
            display: inline-block;
            width: 20px;
            height: 30px;
            background: #40B2E6;
            transform: skew(50deg, 0deg);
            margin-left: 40px;
          }
        }
        p:nth-of-type(1) {
          font-size: 20px;
          font-style: italic;
          line-height: 4;
        }
        p:nth-of-type(2) {
          img {width: 100%}
        }
        p:nth-of-type(3) {
          margin-top: 20px;
          font-size: 20px;
          font-weight: bolder;
          line-height: 2;
        }
      }

      div.future {
        cursor: pointer;
        h1{
          font-size: 26px;
          font-family: 'Open Sans';
          font-weight: bold;
          color: #40B2E6;
          margin-bottom: 60px;
          text-transform: uppercase;
        }
        .para {
          font-size: 20px;
          font-family: 'Open Sans';
          font-weight: bold;
          line-height: 1.5;
          p {margin-top: 32px;}
        }
        .img {
          img {width: 100%;border-radius: 15px;}
        }
        .balls {
          margin: 20px 0 50px;
          display: flex;
          justify-content: space-between;
          position: relative;
          i {
            display: block;
            width: 28px;
            height: 28px;
            background-color: #fff;
            border-radius: 50%;
            position: relative;
            &:before {
              content: '';
              position: absolute;
              top: -30%; left: -60%;
              width: 160%;
              height: 160%;
              background-image: url(./assets/ov.png);
              transition: left .5s;
              background-size: contain;
            }
            &.switchImg:before {
              left: 10%;
            }
          }
          b {
            position: absolute;
            top: 0; bottom: 0;
            left: 0; margin: auto; 
            height: 4px;
            width: 200%;
            background:rgba(212,210,213, .1);
          }
        }
      }
    }
  }
}
</style>
